<script lang="ts" setup>
import { STATUS_SYSTEM_COLOR_DICTIONARY } from "~/utils/statusSystemPrizes";

const { t } = useT();
const { data: appInitData } = useAppInitData();
const { lightCashData, activePackage, discountAmount, handleOpenCash, activePackageIndex, handleChangeActivePreset } =
	useQuickCash();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { depositInfoData } = useFunrizeDepositStreakData();

const rankLeagueType = computed(() => appInitData.value?.rankLeague?.progressData?.section?.type || "bronze");
const bgColor = computed(() => `var(${STATUS_SYSTEM_COLOR_DICTIONARY[rankLeagueType.value]})`);
const hasLastPayProvider = computed(() =>
	Boolean(lightCashData.value.lastPayProvider?.method_id && lightCashData.value.lastPayProvider?.type !== "applepay")
);
const isActiveSeason = computed(() => !appInitData.value?.season?.isAvailable);
const isActiveDepositStreak = computed(() => appInitData.value?.depositStreak?.isActive);
const isFreeSpin = computed(() => activePackage.value?.promoOfferPreset?.freeSpin);

const isShowTitle = computed(
	() =>
		!isFreeSpin && activePackage.value?.promoOfferPreset?.title && activePackage.value?.promoOfferPreset?.imageBanner
);

const isShowTicket = computed(() => {
	if (appInitData.value?.season?.isEnabled) {
		return activePackage.value.money >= appInitData.value.season.minDepositAmount;
	}

	if (appInitData.value?.depositStreak && isActiveDepositStreak.value) {
		return activePackage.value.money >= Number(depositInfoData.value?.minDepositValue);
	}
});

const bannerImg = computed(() => {
	if (activePackage.value?.promoOfferPreset?.imageBanner) {
		return `${baseImageUrl}${activePackage.value?.promoOfferPreset?.imageBanner}`;
	}

	return `${baseImageUrl}${lightCashData.value.retinaBannerUrl || lightCashData.value.x1BannerUrl}`;
});

const badgeText = computed(() => {
	const { badgeLabel = "", bestDeal, mostPopular, rankLeague, freeSpin } = activePackage.value.promoOfferPreset ?? {};
	const parsedBadgeLabel = parseJSON(badgeLabel);
	let text = "";

	if (bestDeal) {
		text = t("Best deal");
	}

	if (mostPopular) {
		text = t("Most popular");
	}

	if (rankLeague) {
		text = t("Status reward");
	}

	if (freeSpin) {
		text = t("offer with FP");
	}

	if (parsedBadgeLabel) {
		return `${text} - ${parsedBadgeLabel.percent} ${parsedBadgeLabel.text}`;
	}

	return text;
});
</script>
<template>
	<div class="quick-cash">
		<AAnimationSkeleton v-if="'skeleton' in lightCashData" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>

		<template v-else-if="lightCashData.packages?.length">
			<ABadge
				v-if="activePackage.promoOfferPreset && badgeText"
				variant="skew"
				background="var(--cebu)"
				:class="{
					'most-popular': activePackage.promoOfferPreset.mostPopular,
					'rank-league': activePackage.promoOfferPreset.rankLeague,
					'free-spin': isFreeSpin
				}"
				autosize
			>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']"> {{ badgeText }}</AText>
			</ABadge>
			<NuxtImg class="image" :src="bannerImg" width="620" height="412" format="avif" alt="quick-cash" />
			<div class="cash-wrap">
				<AText v-if="isShowTitle" as="h3" variant="tirana" :modifiers="['semibold']">
					{{ activePackage.promoOfferPreset?.title }}
				</AText>
				<div class="cash-widget">
					<AText as="h2" variant="tanta" :modifiers="['semibold']">
						{{ t("Select your perfect coin bundle!") }}
					</AText>
					<MHomeQuickCashBanner
						v-if="activePackage"
						:active-package="activePackage"
						:is-show-ticket="isShowTicket"
						:badge-text="badgeText"
						:bg-color="bgColor"
					/>

					<MHomeQuickCashPresets
						:presets="lightCashData.packages"
						:active-index="activePackageIndex"
						@click-preset="handleChangeActivePreset"
					/>

					<MHomeQuickCashPayMethod
						v-if="hasLastPayProvider"
						:last-pay-provider="lightCashData.lastPayProvider"
						@open-cash="handleOpenCash"
					/>

					<AButton variant="primary" size="md" :modifiers="['wide']" @click="handleOpenCash">
						<AText variant="toledo" :modifiers="['uppercase']">
							{{ t("buy now") }}
							<i18n-t v-if="discountAmount" keypath="with {key}% off">
								<template #key>{{ discountAmount }}</template>
							</i18n-t>
						</AText>
					</AButton>
					<div class="pay-methods-icons">
						<NuxtIcon name="24/mc" filled />
						<NuxtIcon name="24/visa" filled />
						<NuxtIcon name="24/apple-pay" filled />
						<NuxtIcon name="24/trustly" filled />
					</div>
				</div>

				<AText v-if="isShowTicket" as="div" class="ticket" variant="turin" :modifiers="['bold']">
					<MTicket :is-deposit-streak="isActiveDepositStreak" :is-season="isActiveSeason" />
				</AText>
			</div>
		</template>
	</div>
</template>
<style lang="scss" scoped>
.quick-cash {
	height: 412px;
	border-radius: 12px;
	background: v-bind(bgColor);
	display: flex;
	align-items: center;
	padding-left: gutter(7);
	position: relative;
	overflow: hidden;

	h2 {
		display: none;

		@include media-breakpoint-down(md) {
			display: block;
			color: var(--chinatown);
		}
	}

	h3 {
		margin-bottom: gutter(2);

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.image {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	@include media-breakpoint-down(md) {
		padding: 0;
		height: auto;
		justify-content: center;
		background: var(--cannes);

		.image {
			display: none;
		}
	}
}

.badge {
	box-shadow: none;
	position: absolute;
	top: 0;
	right: -3px;
	flex-direction: row;
	z-index: 99;

	&.skew::before {
		display: none;
	}

	@include media-breakpoint-down(md) {
		&.skew {
			display: none;
		}
	}

	&::before {
		background-color: inherit;
	}

	&.most-popular {
		background: var(--cabo);
	}

	&.rank-league {
		background: var(--caceres);
	}

	&.free-spin {
		background: var(--chusovoy);
		color: var(--chomutov);
	}
}

.cash-widget {
	display: flex;
	flex-direction: column;
	gap: gutter(1.5);
	width: 328px;
	border-radius: 12px;
	background-color: var(--cannes);
	padding: gutter(2) gutter(1.5) gutter(2.5);
	z-index: 1;

	@media (max-width: 465px) {
		width: 100%;
	}

	.presets {
		margin-bottom: gutter(1);
		justify-content: center;
	}
}

.cash-wrap {
	position: relative;
}

.ticket {
	display: flex;
	align-items: center;
	gap: gutter(0.5);
	line-height: 16px;
	margin-top: 8px;

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.pay-methods-icons {
	display: flex;
	justify-content: center;
	gap: gutter(3);

	.nuxt-icon:deep(svg) {
		width: auto;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}
</style>
