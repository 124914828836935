const useQuickCash = () => {
	const { data: lightCashData, refresh: refreshQuickOfferData } = useAsyncFetch({
		path: "/rest/cash/input/light-cash/",
		method: "get",
		options: {
			cached: true,
			default: () => ({
				skeleton: true,
				initialActivePreset: null,
				applyPayEnabled: false,
				groupPackages: [],
				lastPayProvider: null,
				packages: [],
				upSales: [],
				retinaBannerUrl: "",
				x1BannerUrl: ""
			}),
			transform: (data) => {
				const presetsSet = new Set(data.groupPackages.map(Number));
				const filteredPackages = data.packages
					.filter((offer) => presetsSet.has(offer?.money))
					.sort((a, b) => b.money - a.money);

				return {
					...data,
					packages: filteredPackages,
					initialActivePreset: filteredPackages.findIndex((offer) => !!offer.promoOfferPreset)
				};
			}
		}
	});

	const activePackageIndex = computed(() =>
		lightCashData.value.initialActivePreset < 0 ? 0 : lightCashData.value.initialActivePreset
	);
	const activePackage = computed(() => lightCashData.value?.packages[activePackageIndex.value]);
	const discountAmount = computed(() => {
		if (!activePackage.value?.promoOfferPreset) {
			return 0;
		}

		const { usualPrice, money = 0 } = activePackage.value.promoOfferPreset;
		if (!usualPrice || usualPrice < money) {
			return 0;
		}

		return Math.round(((usualPrice - money) / usualPrice) * 100);
	});

	const handleChangeActivePreset = (value: number) => {
		lightCashData.value.initialActivePreset = value;
	};

	const handleOpenCash = () => {
		if (!activePackage.value) {
			return;
		}

		const url = activePackage.value.promoOfferPreset
			? `/cash/deposit-by-money/${activePackage.value.promoOfferPreset.id}/promoOffer/`
			: `/cash/deposit-by-money/${activePackage.value.preset.id}/preset/`;

		window?.$cash?.$router?.push?.(url);
		window?.$store?.gaCash?.deposit({
			location: "fast_deposit",
			type:
				activePackage.value.relatedUpSaleId || activePackage.value.promoOfferPreset?.relatedPresetIds?.length
					? "upsale"
					: "",
			step: "view_payments_info"
		});
		dispatchGAEvent({ event: "click_button", button_name: "deposit", location: "fast_deposit" });
	};

	useEvent(["DEPOSIT_COMPLETE"], () => {
		setTimeout(() => {
			refreshQuickOfferData();
		}, 1000);
	});

	return { lightCashData, activePackage, discountAmount, activePackageIndex, handleOpenCash, handleChangeActivePreset };
};

export default useQuickCash;
